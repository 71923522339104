import React from 'react';

import Link from '../ui/Link';
import { FormattedMessage, injectIntl } from 'react-intl';

export const AdditionalMobileMenu = injectIntl(({ intl }) => (
  <ul>
    <li>
      <Link to="/academy" title={intl.formatMessage({ id: 'header.navigation.academy' })}>
        <FormattedMessage id="header.navigation.academy" />
      </Link>
    </li>
    <li>
      <Link to="/coworking" title={intl.formatMessage({ id: 'header.navigation.coworking' })}>
        <FormattedMessage id="header.navigation.coworking" />
      </Link>
    </li>
    <li>
      <Link to="/franchise" title={intl.formatMessage({ id: 'header.navigation.franchise' })}>
        <FormattedMessage id="header.navigation.franchise" />
      </Link>
    </li>
  </ul>
));