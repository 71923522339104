import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import styled from 'styled-components';

import IconButton from '../IconButton';
import { media } from '../../../utils/media';
import { prepareSocialMedia } from '../../../utils/formatters';
import { RouteComponentProps } from 'react-router';

enum ViewTypes {
  Desktop = 'desktop',
  Mobile = 'mobile',
}

const NavigationContainer = styled.nav`
  > button, > a {
    position: fixed;
    display: none;
    top: 20px;

    ${media.down.md} {
      display: block;
    }
  }

  > button {
    z-index: 5;
    left: 15px;
  }
  > a {
    right: 15px;
  }
`;

const NavigationList = styled.ul<{ visible: boolean }>`
  margin: 0;
  list-style: none;

  ${media.down.md} {
    position: fixed;
    background-color: black;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    opacity: 0;
    padding: 0 10vw;
    padding-top: 50px;
    right: 0;
    top: 0;
    transition: opacity .3s ease-in;
    visibility: hidden;
    z-index: 3;

    ${({ visible }) => visible && `
      opacity: .95;
      visibility: visible;
    `}
  }
`;

const NavigationItem = styled.li<{ isOnlyFor?: ViewTypes; }>`
  display: inline-block;
  text-shadow: 1px 1px 1px #444;
  font-weight: bold;
  padding: 25px .8vw;
  ${({ isOnlyFor }) => isOnlyFor === ViewTypes.Desktop && `
    padding: 16px 1vw;
    float: right;
  `}

  ${media.up.md} {
    ${({ isOnlyFor }) => isOnlyFor === ViewTypes.Mobile && `
      display: none;
    `}
  }

  ${media.down.md} {
    ${({ isOnlyFor }) => isOnlyFor === ViewTypes.Desktop ? `
      display: none;
    ` : `
      margin: 0;
      padding: 1vh 1vw;
      display: block;
      font-size: 32px;

      > ul {
        list-style: none;
        margin: 1vh -2vw;
        padding: 1vh 0;
        border-top: #640001 3px solid;

        > li {
          padding: 1vh 2vw;
        }
      }
    `}
  }
`;

export const Navigation: React.FC<RouteComponentProps & InjectedIntlProps & {
  additionalDesktop?: React.ReactNode;
  additionalMobile?: React.ReactNode;
  isMobileMenuVisible: boolean;
  onChangeMobileMenuVisible: (isMobileMenuVisible: boolean) => void;
}> = ({
  additionalDesktop,
  additionalMobile,
  children,
  intl,
  isMobileMenuVisible,
  location,
  onChangeMobileMenuVisible,
}) => {
  return (
    <NavigationContainer>
      <NavigationList visible={isMobileMenuVisible}>
        {children && (children as React.ReactNode[])
          .map((navigationItem: React.ReactNode, key) => (
            <NavigationItem
              key={key}
              onClick={() => onChangeMobileMenuVisible(false)}
            >
              {navigationItem}
            </NavigationItem>
          ))
        }
        {additionalDesktop && (
          <NavigationItem
            isOnlyFor={ViewTypes.Desktop}
            onClick={() => onChangeMobileMenuVisible(false)}
          >
            {additionalDesktop}
          </NavigationItem>
        )}
        {additionalMobile && (
          <NavigationItem
            isOnlyFor={ViewTypes.Mobile}
            onClick={() => onChangeMobileMenuVisible(false)}
          >
            {additionalMobile}
          </NavigationItem>
        )}
      </NavigationList>
      <IconButton
        size={36}
        onClick={() => onChangeMobileMenuVisible(!isMobileMenuVisible)}
        name={isMobileMenuVisible ? 'close-menu' : 'open-menu'}
        title={
          isMobileMenuVisible
            ? intl.formatMessage({ id: 'header.control.open-mobile-menu' })
            : intl.formatMessage({ id: 'header.control.close-mobile-menu' })
        }
      />
      <IconButton
        size={36}
        name="instagram"
        as="a"
        href={prepareSocialMedia(location.pathname, intl.locale).instagram}
        target="_blank"
        title={intl.formatMessage({ id: 'footer.social.instagram' })}
      />
    </NavigationContainer>
  );
}
