import { translations } from "./translations";

export const prepareSocialMedia = (
  pathname: string = window.location.pathname,
  locale: string,
) => {
  const { common } = translations.read(locale as 'en' | 'pl');

  switch (pathname.split('/').pop()) {
    case 'tattoos':
      return {
        facebook: common['contact.barbershop.facebook'],
        instagram: common['contact.barbershop.instagram'],
      }
    case 'barbershop': 
    default:
      return {
        facebook: common['contact.tattoos.facebook'],
        instagram: common['contact.tattoos.instagram'],
      };
  }
}

export const direction = (
  sectionKey: number,
  internal: number = 1
) => internal % 2
  ? sectionKey % 2 ? 1 : -1
  : sectionKey % 2 ? -1 : 1;