import React from 'react';
import { FormattedMessage } from 'react-intl';
import PageContainer from '../../components/ui/PageContainer';
import PageSection from '../../components/ui/PageSection';
import Text from '../../components/ui/Text';

export const NotFound: React.FC = () => (
  <PageContainer vCentered hCentered>
    <PageSection>
      <Text size={56} weight="bold">
        <FormattedMessage id="page.not-found" />
      </Text>
    </PageSection>
  </PageContainer>
);
