import styled from 'styled-components';

import { media } from '../../../utils/media';

export const Margin = styled.div<{ desktop?: string; mobile?: string; inline?: boolean; }>`
  display: ${({ inline }) => inline ? 'inline-block' : 'block'};
  margin: ${({ desktop }) => desktop};

  ${({ mobile }) => !!mobile && `
    ${media.down.md} {
      margin: ${mobile};
    }
  `}
`;