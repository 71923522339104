import React from 'react';
import { RouteComponentProps } from 'react-router';
import { InjectedIntlProps } from 'react-intl';
import { Helmet as ReactHelmet } from 'react-helmet';

import meta from '../../meta.json';

type MetaTag = {
  name: string;
  content: string;
}

const prepareTitle = (intl: any, pathname: string) => {
  let title = intl.formatMessage({ id: 'app.title' });
  const pageKey = pathname.split('/').pop();

  if (!pageKey) {
    title += ` - ${intl.formatMessage({ id: 'header.navigation.home' })}`;
  } else if (`header.navigation.${pageKey}` in intl.messages) {
    title += ` - ${intl.formatMessage({ id: `header.navigation.${pageKey}` })}`;
  }

  return title;
}

const prepareMetaTags = (pathname: string) => {
  let { base: metaTags, pages } = meta as {
    base: MetaTag[],
    pages: { [x: string]: MetaTag[] }
  };
  const page = Object.keys(pages)
    .find((page) => page === pathname || pathname.includes(page));

  return (!!page ? [...metaTags, ...pages[page]] : metaTags)
    .map((metaTag, key) => (
      <meta
        key={key}
        name={metaTag.name}
        content={metaTag.content}
      />
    ));
}

export const Helmet: React.FC<RouteComponentProps & InjectedIntlProps> = ({
  location,
  intl,
}) => {
  const { pathname } = location;

  return (
    <ReactHelmet>
      <title>
        {prepareTitle(intl, pathname)}
      </title>
      {prepareMetaTags(pathname)}
    </ReactHelmet>
  );
}