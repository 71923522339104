import styled from 'styled-components';
import { media } from '../../../utils/media';

export enum VisibleTypes {
  Desktop = 'desktop',
  Mobile = 'mobile',
}

export const VisibleSection = styled.div<{ isOnlyFor: VisibleTypes; inline?: boolean; }>`
  display: ${({ inline }) => inline ? 'inline-block' : 'block'};

  ${({ isOnlyFor }) => {
    switch (isOnlyFor) {
      case VisibleTypes.Desktop:
        return `
          ${media.down.md} {
            display: none;
          }
        `;
      case VisibleTypes.Mobile:
        return `
          ${media.up.lg} {
            display: none;
          }
        `;
    }
  }}
`;