import React, { useState, useContext } from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import styled from 'styled-components';

import { AppContext } from '../../App';
import Navigation from '../ui/Navigation';
import { AdditionalDesktopMenu } from './AdditionalDesktopMenu';
import { AdditionalMobileMenu } from './AdditionalMobileMenu';
import Link from '../ui/Link';
import Logo from '../Logo';

const AnimatedHeader = styled.div<{ state: TransitionStatus }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 5px;
  z-index: 3;

  opacity: ${({ state }) => (state === 'entering' || state === 'entered') ? 1 : 0};
  visibility: ${({ state }) => (state === 'exited' ? 'hidden' : 'visible')};
  transition: opacity .3s ease-in;
`;

export const Header: React.FC<InjectedIntlProps> = ({ intl }) => {
  const [ isMobileMenuVisible, setMobileMenuVisible ] = useState(false);
  const { isInterfaceVisible, setInterfaceVisible } = useContext(AppContext);

  const handleLogoClick = () => {
    setMobileMenuVisible(false);
    if (!isInterfaceVisible) {
      setInterfaceVisible!(true);
    }
  }

  return (
    <header>
      <Transition in={isInterfaceVisible} timeout={300}>
        {(state) => (
          <AnimatedHeader state={state}>
            <Navigation
              additionalDesktop={<AdditionalDesktopMenu />}
              additionalMobile={<AdditionalMobileMenu />}
              isMobileMenuVisible={isMobileMenuVisible}
              onChangeMobileMenuVisible={setMobileMenuVisible}
            >
              <Link exact to="/" title={intl.formatMessage({ id: 'header.navigation.home' })}>
                <FormattedMessage id="header.navigation.home" />
              </Link>
              <Link to="/our-works" title={intl.formatMessage({ id: 'header.navigation.our-works' })}>
                <FormattedMessage id="header.navigation.our-works" />
              </Link>
              <Link to="/services" title={intl.formatMessage({ id: 'header.navigation.services' })}>
                <FormattedMessage id="header.navigation.services" />
              </Link>
              <Link to="/shop" title={intl.formatMessage({ id: 'header.navigation.shop' })}>
                <FormattedMessage id="header.navigation.shop" />
              </Link>
              <Link to="/about-us" title={intl.formatMessage({ id: 'header.navigation.about-us' })}>
                <FormattedMessage id="header.navigation.about-us" />
              </Link>
            </Navigation>
          </AnimatedHeader>
        )}
      </Transition>
      <Logo
        situation={!!isInterfaceVisible}
        onClick={handleLogoClick}
      />
    </header>
  );
}
