import React from 'react';
import styled from 'styled-components';

let prevHrefs: { [x: string]: string } = {};

// @ts-ignore
window['prevHrefs'] = prevHrefs;

const Button = styled.button<{ componentId: string; size: number; href?: string; to?: string; }>`
  outline: none;
  background-color: transparent;
  border-width: 0;
  padding: 0;
  transition: opacity .2s ease-in, transform .2s ease-in;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  ${({ onClick, href, to, disabled }) => (!!href || !!to || !!onClick) && !disabled && `
    cursor: pointer;
    opacity: .8;

    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }
  `}
  ${({ disabled }) => disabled && `
    opacity: .5;
    cursor: not-allowed;
  `}

  ${({ componentId, href }) => {
    if (!!href) {
      if (prevHrefs[componentId] && prevHrefs[componentId] !== href) {
        prevHrefs[componentId] = href;

        return `
          display: inline-block;

          @keyframes rotate-${Date.now()} {
            100% { transform: rotate(360deg) }
          }

          animation: rotate-${Date.now()} .5s ease-out;
        `;
      } else if (!prevHrefs[componentId]) {
        prevHrefs[componentId] = href;
      }
    }
  }}

  > svg {
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;

    path {
      fill: ${({ color }) => color || 'white'} !important;
    }
  }
`;

export class IconButton extends React.PureComponent<any & {
  size: number;
  name: string;
}> {
  public state: {
    IconComponent: string | null;
  } = {
    IconComponent: null,
  };

  private componentId: string = `${this.props.name}-${Date.now()}`;

  public componentDidMount() {
    this.importIconComponent();
  }

  public componentDidUpdate(prevProps: any) {
    if (this.props.name !== prevProps.name) {
      this.importIconComponent();
    }
  }

  public render() {
    const { name, size = 30, children, ...rest } = this.props;
    const { IconComponent } = this.state;
    
    if (!IconComponent) {
      return null;
    }

    return (
      <Button
        componentId={this.componentId}
        size={size}
        {...rest}
      >
        <IconComponent />
      </Button>
    );
  }


  private async importIconComponent() {
    const { name } = this.props;

    await import(`!svg-react-loader!../../../assets/images/icons/${name}.svg`)
      .then((resource) => {
        this.setState({ IconComponent: resource.default });
      });
  }
}