import styled from 'styled-components';
import { media } from '../../../utils/media';

export const PageContainer = styled.section<{ vCentered?: boolean; hCentered?: boolean; bg?: string; }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  ${({ bg }) => !!bg && `
    background-color: ${bg};
  `}

  padding: 15vh 10vw 22vh 10vw;
  ${media.down.sm} {
    padding-bottom: 18vh;
    padding: 15vh 5vw 22vh 5vw;
  }

  @keyframes fade {  
    0% { opacity: 0 }
    100% { opacity: 1 }
  }

  animation-duration: .3s;
  animation-name: fade;

  ${({ hCentered }) => hCentered && `
    align-items: center;
  `}
  ${({ vCentered }) => vCentered && `
    justify-content: center;
  `}
`;