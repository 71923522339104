const fetchTranslations = () => {
  let status = 'pending';
  let translations: any;
  let suspender = Promise.all([
    fetch(`/translations/en.json?t=${Date.now()}`),
    fetch(`/translations/pl.json?t=${Date.now()}`),
  ])
    .then((responses) => {
      return Promise.all(responses.map(res => res.json()));
    })
    .then(([ en, pl ]) => {
      translations = { en, pl };
      status = 'success';
    });

  return {
    read: (locale: 'en' | 'pl') => {
      if (status === 'pending') {
        throw suspender;
      } else if (status === 'success') {
        return translations[locale];
      }
    }
  }
}

export const translations = fetchTranslations();