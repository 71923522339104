import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import styled from "styled-components";
import { media } from '../../utils/media';

import logoWhite from '../../assets/images/logo-white.png';
import { Link } from 'react-router-dom';

const LogoContainer = styled.div<{ situation: boolean; }>`
  position: fixed;
  max-width: 270px;
  width: 42vw;
  top: 54px;
  right: 50%;
  transform: translateX(50%) translateY(-50%);
  transition: transform .3s ease-in;
  z-index: 3;

  ${media.down.xs} {
    top: 45px;
  }

  ${({ situation }) => !situation && `
    transform: translateX(50vw) translateY(-65%) scale(.7);
  `}
`

const LogoImage = styled.img`
  width: 100%;
`;

export const Logo: React.FC<InjectedIntlProps & {
  situation: boolean;
  onClick?: () => void;
}> = ({ situation, intl, onClick }) => (
  <LogoContainer situation={situation}>
    <Link
      to="/"
      title={intl.formatMessage({ id: 'app.title' })}
      onClick={onClick}
    >
      <LogoImage
        src={logoWhite}
        alt={intl.formatMessage({ id: 'app.title' })}
      />
    </Link>
  </LogoContainer>
);