import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Montserrat';
    font-size: 14px;
    background-color: black;
    color: white;
  }

  a {
    color: white;
    text-decoration: none;
    transition: color .1s ease-in;

    &:hover {
      color: red;
    }
  }
`;