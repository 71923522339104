import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import * as serviceWorker from './serviceWorker';

ReactDOM.render((
  <Suspense fallback="loading...">
    <App />
  </Suspense>
), document.getElementById('root'));
serviceWorker.unregister();
