import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import Text from '../ui/Text';
import Margin from '../ui/Margin';
import Button from '../ui/Button';

export const AdditionalDesktopMenu = injectIntl(({ intl }) => (
  <React.Fragment>
    <Text size={14}>
      <Margin desktop="0 1vw 0 0" inline>
        10:00-20:00
      </Margin>
      <a href={`tel:${intl.formatMessage({ id: 'contact.mobileNumber' })}`}>
        <Text weight="bold">{intl.formatMessage({ id: 'contact.mobileNumber' })}</Text>
      </a>
    </Text>
    <Margin desktop="0 0 0 2vw" inline>
      <Button
        as={Link}
        to="/book-now"
        title={intl.formatMessage({ id: 'header.navigation.book-now' })}
      >
        <Text weight="bold">
          <FormattedMessage id="header.navigation.book-now" />
        </Text>
      </Button>
    </Margin>
  </React.Fragment>
));