import React from 'react';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';

const BaseArrows = styled.div`
  flex: 1;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;

  > div {
    border-color: #1B0000;
    border-style: solid;
    border-width: 0;
    width: 90vh;
    height: 90vh;
    transform: rotate(45deg);
  }
`;
const LeftArrows = styled(BaseArrows)`
  left: 0;
  transform: translateX(-75vh);
  @keyframes slideLeft {
    100% { transform: translateX(225vh) }
  }

  > div {
    border-top-width: 4vh;
    border-right-width: 4vh;
    margin-left: -83vh;
  }
`;

const RightArrows = styled(BaseArrows)`
  right: 0;
  justify-content: flex-end;
  transform: translateX(75vh);
  @keyframes slideRight {
    100% { transform: translateX(-225vh) }
  }

  > div {
    border-left-width: 4vh;
    border-bottom-width: 4vh;
    margin-right: -83vh;
  }
`;

const Loader = styled.div<{ state: TransitionStatus; }>`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background: #000;
  opacity: .5;

  ${({ state }) => {
    switch(state) {
      case 'entering':
      case 'entered':
        return `
          @keyframes fadeIn {
            100% { opacity: .5 }
          }
          animation: fadeIn .2s ease-in forwards;

          > ${LeftArrows} {
            animation: slideLeft 2s ease-in infinite;
          }
          > ${RightArrows} {
            animation: slideRight 2s ease-in infinite;
          }
        `;
      case 'exiting':
        return `
          @keyframes fadeOut {
            50% { opacity: .5 }
            100% { opacity: 0 }
          }
          animation: fadeOut 1s ease-in forwards;

          > ${LeftArrows} {
            animation: slideLeft 2s ease-in infinite;
          }
          > ${RightArrows} {
            animation: slideRight 2s ease-in infinite;
          }
        `;
    }
  }}
`;

export const PageLoader: React.FC<{
  isPageLoading: boolean;
}> = ({ isPageLoading }) => (
  <Transition
    in={isPageLoading}
    timeout={1000}
    mountOnEnter
    unmountOnExit
  >
    {(state) => (
      <Loader state={state}>
        <LeftArrows>
          <div /><div /><div /><div /><div />
        </LeftArrows>
        <RightArrows>
          <div /><div /><div /><div /><div />
        </RightArrows>
      </Loader>
    )}
  </Transition>
);

export class PageLoadHandler extends React.PureComponent<{
  togglePageLoad: () => void;
}> {
  public componentDidMount() {
    this.props.togglePageLoad();
  }

  public componentWillUnmount() {
    this.props.togglePageLoad();
  }

  public render() {
    return null;
  }
}