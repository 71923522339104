import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { IntlProvider, addLocaleData } from 'react-intl';
import localePL from 'react-intl/locale-data/pl';
import styled from 'styled-components';

import Helmet from './components/Helmet';
import Header from './components/Header';
import Footer from './components/Footer';
import PageLoader, { PageLoadHandler } from './components/PageLoader';

import Home from './pages/Home';
import BookNow from './pages/BookNow';
import OurWorks from './pages/OurWorks';
import Shop from './pages/Shop';
import TextPage from './pages/TextPage';
import NotFound from './pages/NotFound';

import { GlobalStyles } from './utils/global-styles';
import { cloudinary } from './utils/cloudinary';
import { translations } from './utils/translations';

export interface IAppContext {
  isInterfaceVisible: boolean;
  language: 'en' | 'pl';
  setInterfaceVisible: (isInterfaceVisible: boolean) => void;
  setLanguage: (language: 'en' | 'pl') => void;
};

export const AppContext = React.createContext<Partial<IAppContext>>({});
addLocaleData(localePL);

const AppContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  background-size: cover;
  background-position-x: 50%;
  background-image: url(${cloudinary.prepareUrl('barber-bw', {
    resource_type: 'video',
    startOffset: 0,
    format: (window as any).hasWebPSupport ? 'webp' : 'jpg',
    quality: 80,
    rawTransformation: 'e_blur:230/e_brightness:-50'
  })});
`;

class App extends React.PureComponent {
  public state: {
    isBookNowVisible: boolean;
    isInterfaceVisible: boolean;
    isPageLoading: boolean;
    language: IAppContext['language'];
  } = {
    isBookNowVisible: false,
    isInterfaceVisible: true,
    isPageLoading: false,
    language: (localStorage.getItem('lng') as IAppContext['language']) || 'pl',
  }

  public componentDidMount() {
    window.addEventListener('popstate', () => {
      const { isInterfaceVisible } = this.state;

      if (!isInterfaceVisible) {
        this.handleSetInterfaceVisible(true);
        window.history.forward();
      }
    });
  }

  public render() {
    const {
      isInterfaceVisible,
      isPageLoading,
      language,
    } = this.state;

    const contextValues = {
      isInterfaceVisible,
      language,
      setInterfaceVisible: this.handleSetInterfaceVisible ,
      setLanguage: this.handleSetLanguage,
    };

    const { common, ...pages} = translations.read(language);

    return (
      <IntlProvider
        locale={language}
        messages={common}
      >
        <Router>
          <Helmet />
          <AppContainer>
            <AppContext.Provider value={contextValues}>
              <Header />
      
              <Suspense fallback={<PageLoadHandler togglePageLoad={this.togglePageLoading} />}>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route path="/book-now" component={BookNow} />
                  <Route path="/our-works" component={OurWorks} />
                  <Route path="/shop" component={Shop} />
                  <Route path={`/:pageAlias(${Object.keys(pages).join('|')})`} component={TextPage} />
                  <Route path="*" component={NotFound} />
                </Switch>
              </Suspense>

              <Footer
                visible={isInterfaceVisible}
                onLanguageSwitch={this.handleSetLanguage}
              />
            </AppContext.Provider>
          </AppContainer>
          <PageLoader
            isPageLoading={isPageLoading}
          />
          <GlobalStyles />
        </Router>
      </IntlProvider>
    );
  }

  private handleSetLanguage = (language: IAppContext['language']) => {
    localStorage.setItem('lng', language);
    this.setState({ language });
  }

  private handleSetInterfaceVisible = (isInterfaceVisible: boolean) => {
    this.setState({ isInterfaceVisible });
  }

  private togglePageLoading = () => {
    this.setState({ isPageLoading: !this.state.isPageLoading });
  }
}

export default App;
