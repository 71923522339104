export interface IBreakpoints {
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
}

export const BP_OFFSET = 0.02;
export const BREAKPOINTS: {
  [x: string]: number;
} = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1600,
};

let previousBp: number | null = null;

export const media: {
  up: IBreakpoints;
  down: IBreakpoints;
  only: IBreakpoints;
  retina: string;
} = Object.keys(BREAKPOINTS)
  .reduce((result, key) => {
    const bp = BREAKPOINTS[key];
    const smallerBp = bp - BP_OFFSET;

    result.up[key] = !!previousBp ? `@media (min-width: ${previousBp}px)` : null;
    result.down[key] = `@media (max-width: ${smallerBp}px)`;
    if (key === 'xs') {
      result.only[key] = `@media (max-width: ${smallerBp}px)`;
    } else if (key === 'xl') {
      result.only[key] = `@media (min-width: ${previousBp}px)`;
    } else {
      result.only[key] = `@media (min-width: ${previousBp}px) and (max-width: ${smallerBp}px)`;
    }

    previousBp = bp;

    return result;
  }, {
    down: {},
    only: {},
    retina: '@media (-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2), (min-resolution: 192dpi)',
    up: {},
  } as any);
