import styled from 'styled-components';

interface ITextProps {
  color?: string;
  size?: number;
  weight?: number | string;
  family?: string;
}

export const Text = styled.span<ITextProps>`
  color: ${({ color }) => !!color ? color : 'inherit'};
  font-size: ${({ size }) => !!size ? `${size}px` : 'inherit'};
  font-weight: ${({ weight }) => !!weight ? weight : 'inherit'};
  font-family: ${({ family }) => !!family ? family : 'inherit'};
`;