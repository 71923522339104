import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';

import styled from 'styled-components';

import IconButton from '../ui/IconButton';
import Margin from '../ui/Margin';
import Text from '../ui/Text';
import VisibleSection, { VisibleTypes } from '../ui/VisibleSection';
import { AdditionalMobileMenu } from '../Header/AdditionalMobileMenu';
import { media } from '../../utils/media';
import { prepareSocialMedia } from '../../utils/formatters';

const AnimatedFooter = styled.footer<{ state: TransitionStatus }>`
  position: fixed;
  left: 10vw;
  right: 10vw;
  bottom: 3vh;
  z-index: 2;

  ${media.down.sm} {
    bottom: 2vh;
  }

  opacity: ${({ state }) => (state === 'entering' || state === 'entered') ? 1 : 0};
  visibility: ${({ state }) => (state === 'exited' ? 'hidden' : 'visible')};
  transition: opacity .3s ease-in;
`;

const FooterContainer = styled.div`
  display: flex;
  ${media.down.sm} {
    flex-direction: column;
  }
`;

const FooterLeftColumn = styled.div`
  flex: 2;

  ${media.down.sm} {
    align-self: center;
    margin-bottom: 2vh;
  }
`;

const InformationSection = styled.div`
  padding: 0 0 0 22px;

  ${media.down.sm} {
    padding: 2px 0 0 0;
    font-size: 13px;
    text-align: center;
  }
`;

const FooterMenu = styled.div`
  > ul {
    padding: 0 20px;
    font-weight: 700;
    > li:not(:first-of-type) {
      margin: 1vh 0 0 0;
    }
  }
`;

const FooterRightColumn = styled.div`
  flex: 1;
  padding-bottom: 5px;
  text-align: right;
  align-self: flex-end;
  ${media.down.md} {
    align-self: center;
  }
`;

export const Footer: React.FC<RouteComponentProps & InjectedIntlProps & {
  visible: boolean;
  onLanguageSwitch: (language: 'en' | 'pl') => void;
}> = ({ visible, onLanguageSwitch, intl, location }) => {
  return (
    <Transition in={visible} timeout={300}>
      {(state) => (
        <AnimatedFooter state={state}>
          <FooterContainer>
            <FooterLeftColumn>
              <div>
                <Margin desktop="0 7px 0 0" inline>
                  <IconButton name="location" size={14} />
                </Margin>
                <Text size={16} weight="bold">
                  <a href="//google.com/maps/place/Bounty+barber+shop+%26+tattoo/@51.1008618,17.0486704,17z/data=!3m1!4b1!4m13!1m7!3m6!1s0x470fc27d0b1a6301:0x36032d0502afb39!2zUHLEhWR6ecWEc2tpZWdvIDEzLCA1MC00MzQgV3JvY8WCYXcsIFBvbGFuZA!3b1!8m2!3d51.1008618!4d17.0508591!3m4!1s0x470fc3a22c13e793:0x12cec3f2c9c6c5f1!8m2!3d51.1008618!4d17.0508591" target="_blank" rel="noopener noreferrer">
                    {intl.formatMessage({ id: 'contact.address' })}
                  </a>
                </Text>
                
                <VisibleSection isOnlyFor={VisibleTypes.Mobile}>
                  <InformationSection>
                    10:00-20:00{' '}
                    <a href={`tel:${intl.formatMessage({ id: 'contact.mobileNumber' })}`}>
                      <Text weight="bold">
                        {intl.formatMessage({ id: 'contact.mobileNumber' })}
                      </Text>
                    </a>
                  </InformationSection>
                </VisibleSection>
              </div>

              <VisibleSection isOnlyFor={VisibleTypes.Desktop}>
                <FooterMenu>
                  <AdditionalMobileMenu />
                </FooterMenu>
              </VisibleSection>
            </FooterLeftColumn>
            <FooterRightColumn>
              <div style={{ minWidth: '60%', float: 'left', padding: '7px 15px 0 0' }}>
                <a
                  href="#english"
                  title={intl.formatMessage({ id: 'footer.language.english' })}
                  onClick={() => onLanguageSwitch('en')}
                >
                  <Text size={12}>
                    <FormattedMessage id="footer.language.english" />
                  </Text>
                </a>
                {' | '}
                <a
                  href="#polish"
                  title={intl.formatMessage({ id: 'footer.language.polish' })}
                  onClick={() => onLanguageSwitch('pl')}
                >
                  <Text size={12}>
                    <FormattedMessage id="footer.language.polish" />
                  </Text>
                </a>
              </div>
              <VisibleSection isOnlyFor={VisibleTypes.Desktop} inline>
                <Margin desktop="0 10px 0 0" inline>
                  <IconButton
                    name="instagram"
                    size={32}
                    as="a"
                    href={prepareSocialMedia(location.pathname, intl.locale).instagram}
                    target="_blank"
                    title={intl.formatMessage({ id: 'footer.social.instagram' })}
                  />
                </Margin>
              </VisibleSection>
              <IconButton
                name="facebook"
                size={32}
                as="a"
                href={prepareSocialMedia(location.pathname, intl.locale).facebook}
                target="_blank"
                title={intl.formatMessage({ id: 'footer.social.facebook' })}
              />
            </FooterRightColumn>
          </FooterContainer>
        </AnimatedFooter>
      )}
    </Transition>
  );
}
