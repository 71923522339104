import styled from 'styled-components';
import { media } from '../../../utils/media';

export const Button = styled.button<{ to?: string; }>`
  outline: 0;
  font-size: inherit;
  border-radius: 20px;
  border: red 1px solid;
  padding: 10px 2vw;
  color: white;
  background-color: red;
  transition: background-color .2s ease-in, border-color .2s ease-in, filter .3s ease-in;
  text-shadow: none;
  cursor: pointer;

  &:disabled {
    filter: grayscale(100%);
  }

  ${media.down.md} {
    padding: 10px 30px;
  }

  &:hover {
    color: inherit;
    border-color: #d00;
    background-color: #d00;
  }
`;