import styled from 'styled-components';

export const PageSection = styled.article<{ animationDirection?: 1 | -1 }>`
  padding: 1vh 5px;
  text-shadow: 0 0 1px white;
  line-height: 25px;

  ${({ animationDirection }) => !!animationDirection && `
    transform: translateX(${animationDirection * 100}%);

    @keyframes translate {
      100% { transform: translateX(0%) }
    }

    animation: translate .3s ease-out forwards;
  `}
`;