import React from 'react';
import { Link as RouterLink, LinkProps, RouteComponentProps } from 'react-router-dom';

import styled from 'styled-components';

const CustomLink = styled(RouterLink)<LinkProps & { focused?: number; }>`
  text-decoration: none;
  color: white;
  display: inline-block;
  outline: 0;

  ${({ focused }) => Boolean(focused) ? `
    color: red;
  ` : `
    color: white;
    transition: color .1s ease-in;

    &:hover, &:focus {
      color: red;
    }
  `}
`;


export const Link: React.FC<RouteComponentProps & LinkProps & {
  exact?: boolean;
}> = ({
  children,
  history,
  location,
  match,
  staticContext,
  exact,
  ...rest
}) => {
  const focused = exact 
    ? rest.to === location.pathname 
    : location.pathname.includes(rest.to as string);

  return (
    <CustomLink
      focused={focused ? 1 : 0}
      {...rest}
    >
      {children}
    </CustomLink>
  );
}
