import {
  Cloudinary as CloudinaryCore,
  Util,
  Configuration,
  Transformation,
} from 'cloudinary-core';

export interface ICloudinaryResource {
  context?: {
    custom: {
      [x: string]: string;
    }
  }
  created_at: string;
  format: string;
  height: number;
  public_id: string;
  type: string;
  version: number;
  width: number;
};

class Cloudinary {
  private options: Transformation.Options;

  public constructor(options: Configuration.Options) {
    this.options = options;
  }

  public prepareUrl(publicId: string, options: Transformation.Options | Transformation.VideoOptions = {}) {
    return new CloudinaryCore(Util.withSnakeCaseKeys(this.options))
      .url(publicId, Util.withSnakeCaseKeys(options));
  }

  public loadMediaFolder(mediaFolder: string): Promise<{
    resources: ICloudinaryResource[],
    updated_at: string;
  }> {
    const preparedUrl = this.prepareUrl(mediaFolder, {
      cloudName: this.options.cloudName,
      format: 'json',
      type: 'list',
    });

    return fetch(preparedUrl)
      .then(res => res.json());
  }
}

export const cloudinary = new Cloudinary({
  apiKey: '532758313627287',
  apiSecret: 'xfuXkaxG4TzGaAOYXPwoE7OrZto',
  cloudName: 'bountystudio',
});